<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="3">
        <v-text-field
          v-model="record.LastSyncDuration"
          label="Last Sync Duration"
          type="number"
          min="0"
          step="1"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-if="record"
          v-model="record.DurationType"
          :items="DurationType"
          label="Type"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="record.DoesNotExistCount"
          label="Does Not Exist Count"
          type="number"
          min="0"
          step="1"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          outlined
          v-model="record.isDistinctDocs"
          label="Distinct Doc Scans"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-checkbox
          outlined
          v-model="record.Status"
          label="Status"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time1"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="record.ActiveFrom"
              label="Active From"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu1"
            v-model="record.ActiveFrom"
            full-width
            @click:minute="$refs.menu.save(time1)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time2"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="record.ActiveTo"
              label="Active To"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="record.ActiveTo"
            full-width
            @click:minute="$refs.menu.save(time2)"
          ></v-time-picker>
        </v-menu>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          v-if="record"
          v-model="record.FieldsTemplate"
          :items="templates"
          item-text="Name"
          item-value="id"
          label="Fields Template"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="record.NotifyAfter"
          label="Send Report After"
          type="number"
          min="0"
          step="1"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-if="record"
          v-model="record.NotifyType"
          :items="DurationType"
          label="Type"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    initial: {
      type: Object,
    },
  },
  data: () => ({
    record: {},
    loader: false,
    templates: [],
    mandatory: true,
    status: true,
    menu2: false,
    menu1: false,
    time1: "",
    time2: "",
    DurationType: ["hours", "minutes"],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },

  methods: {
    init(val) {
      console.log(val, "initial");
      this.record = { ...val };
    },

    sendData() {
      const self = this;
      console.log(this.record);
      this.$emit("data", this.record, () => {
        self.loader = false;
      });
    },
    getFieldTemplates() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/fields_template`)
        .then((res) => {
          console.log(res);
          self.templates = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getFieldTemplates();
  },
};
</script>